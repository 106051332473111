$assetsPath: '/resources/shopping/images/themes/easytahiti';

// Brand colors
// -------------------------
$brand-primary: #35B7E9;
$brand-featured: #228AE6;
$brand-special: #FDB714;
$brand-info: #0B7285;
$brand-success: #396F3A;
$brand-active: #E3FAFC;
$brand-danger: #C92A2A;
$brand-warning: #8C6303;
$brand-alternate: #CCC;

// Grays
// -------------------------
$almost-white: #EEE;
$gray-lighter: #DEE2E6;
$gray-light: #ADB5DB;
$gray-medium: #868E96;
$gray-cool: #687090;
$gray: #495057;
$gray-dark: #000;

// Typography
// -------------------------
$font-family-base: Tahoma, Verdana, Arial;
$text-color: $gray-dark;

$headings-font-family: $font-family-base;
$headings-text-color: $gray-dark;

$font-size-h1: 30px;
$font-size-h2: 22px;
$font-size-h3: 16px;
$font-size-h4: 15px;

$h1-margin-vertical: 20px;
$h2-margin-vertical: 16px;
$h3-margin-vertical: 14px;
$h4-margin-vertical: $h3-margin-vertical;

// Links
// -------------------------
$link-color: #0047B3;
$link-hover-color: $link-color;

// Buttons
// -------------------------
// Primary
$btn-primary-color: $gray-dark;
$btn-primary-bg-start: #8AD8F4;
$btn-primary-bg-end: #5DB6E8;
$btn-primary-border: #8AD8F4;

$btn-primary-bg-start-hover: $brand-special;
$btn-primary-bg-end-hover: $brand-special;
$btn-primary-border-hover: $brand-special;

$btn-primary-bg-color-active: $brand-special;
$btn-primary-border-active: $brand-special;

// Default
$btn-default-color: $gray-dark;
$btn-default-bg-start: $btn-primary-bg-start;
$btn-default-bg-end: $btn-primary-bg-end;
$btn-default-border: $btn-primary-border;

$btn-default-bg-start-hover: $brand-special;
$btn-default-bg-end-hover: $brand-special;
$btn-default-border-hover: $brand-special;

$btn-default-bg-color-active: $brand-special;
$btn-default-border-active: $brand-special;

// Secondary
$btn-secondary-color: #FFF;
$btn-secondary-bg: $gray;
$btn-secondary-border: $gray;

$btn-secondary-color-hover: $btn-secondary-color;
$btn-secondary-color-active: $btn-secondary-color-hover;

// Disabled
$btn-disabled-color: $gray;
$btn-disabled-bg-color: #D8D8D8;

@include gradient-buttons;

// header
// -------------------------
$site-header-background: #FFF;
$site-header-logo-primary-file-path: "#{$assetsPath}/easytahiti-logo.gif";

// Footer
// -------------------------
$site-footer-border-top-width: 1px;
$site-footer-background-color: #FFF;

// Pagination
// -------------------------
$pagination-active-color: $gray-dark;

$pagination-hover-bg: $gray-lighter;
$pagination-hover-color: $gray-dark;

// Page Aside
// -------------------------
$aside-contents-section-top-header-background-color: $brand-primary;
$section-header-border-bottom-width: 1px;

// Misc
// -------------------------
$filter-slider-selection-background-color: $brand-special;
$badge-color: $gray-dark;
$star-rating-active-text-color: $brand-special;

// Promotional Banners
// -------------------------
// Promotion Messages
$rate-deal-bg-color: $brand-primary;
$rate-deal-text-color: $gray-dark;

// Exclusive deals
$product-deal-exclusive-bg-color: $brand-primary;
$product-deal-exclusive-text-color: $gray-dark;
$product-deal-exclusive-bg-color_handheld: $brand-primary;
$product-deal-exclusive-text-color_handheld: $gray-dark;

// Client approved
$product-deal-client-approved-bg-color: $brand-primary;
$product-deal-client-approved-text-color: $gray-dark;
$product-deal-client-approved-bg-color_handheld: $brand-primary;
$product-deal-client-approved-text-color_handheld: $gray-dark;

// Room promotions
$promotion-notation-bg-color: $brand-primary;
$promotion-notation-text-color: $gray-dark;

// Alerts
// -------------------------
$alert-warning-text: $brand-warning;
$alert-warning-border: $brand-warning;

// Hero marketing cards
$hero-message-text-color: $gray-dark;
$hero-message-text-color_handheld: $gray-dark;

// nav
$navbar-default-toggle-hover-bg: #FFF;

// Loyalty promotions
$loyalty-deal-bg-color: $brand-success;
$loyalty-deal-text-color: #FFF;

// Handheld (<480px)
$site-header-brand-container-col-width-handheld: 12;
$site-header-loyalty-container-col-width-handheld: 0;

// $screen-lg +
$site-header-brand-container-col-width-lg: 4;
$site-header-loyalty-container-col-width-lg: 8;

// Custom CSS
// -------------------------
#search-form-container .btn-hero-group  {
  .active {
    background-image: none;
  }

  .btn {
    border: none;
  }
}

// Gradient colors override some variables like $btn-default-border
.btn .btn-default {
  .hover .active {
    border: $brand-special;
  }
}

#site-header {
  .logo-primary {
    min-height: 80px;
  }
}

div .pagination {
  > .active > a {
    outline: none;
  }
}

.filters-container .filter legend h3 {
  font-size: $font-size-h3;
}

#aside-contents section header {
  border-bottom: none;
}

.case-handheld {
  //#site-header .logo-primary {
  //  background-size: 90%;
  //}

  .filters-container .filter legend h3 {
    font-size: 18px;
  }

  .page-body .btn-hero-group .btn-primary {
    border: none;
  }

  .navbar-default .navbar-header .navbar-toggle {
    color: $gray-dark;
  }
}